export function IconChevronRight() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.214001 10.4714C-0.0740144 10.1803 -0.0708643 9.71059 0.220762 9.42258L4.19974 5.49991L0.220763 1.57724C-0.0708531 1.28922 -0.0740148 0.819568 0.214002 0.528401C0.502467 0.236786 0.972574 0.233625 1.26465 0.521641L5.77914 4.97209C5.92022 5.11136 6 5.30158 6 5.49989C6 5.6982 5.92022 5.88842 5.77914 6.02769L1.26465 10.4781C0.972585 10.7662 0.502467 10.763 0.214001 10.4714Z"
        fill="currentColor"
      />
    </svg>
  );
}
