export function IconArrowRight() {
  return (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5762 0.238644L16.7614 5.42387C17.0796 5.74206 17.0796 6.25795 16.7614 6.57614L11.5762 11.7614C11.258 12.0795 10.7421 12.0795 10.4239 11.7614C10.1057 11.4432 10.1057 10.9273 10.4239 10.6091L14.2182 6.81478L0.976074 6.81478L0.976074 5.18522L14.2182 5.18522L10.4239 1.39092C10.1057 1.07272 10.1057 0.556835 10.4239 0.238644C10.7421 -0.0795469 11.258 -0.0795469 11.5762 0.238644Z"
        fill="currentColor"
      />
    </svg>
  );
}
