import React from 'react';
import Link from 'next/link';

import { IconMenu } from '../../icons/IconMenu';
import { EnumSection } from '../../sections/sections';
import { ID_MENU, MENU_ITEMS } from '../../constants';
import PopupMenu from './PopupMenu';
import { classNames } from '@/util/utils';

import styles from './Menu.module.css';

export interface MenuProps {
  className?: string;
  scrollHasStarted: boolean;
  section: EnumSection;
}

export default function Menu({ className, scrollHasStarted, section }: MenuProps) {
  const [showMenu, setShowMenu] = React.useState(false);

  return (
    <>
      <div
        id={ID_MENU}
        className={classNames(className, styles.menuContainer, scrollHasStarted && styles.stuck)}
      >
        <Link href="/" className={styles.logo}>
          <h2>Open Brain Institute</h2>
        </Link>

        <div className={styles.items}>
          {MENU_ITEMS.map(({ caption, index, slug }) => (
            <Link
              key={slug}
              href={slug}
              className={classNames(index === section && styles.selected)}
            >
              {caption}
            </Link>
          ))}

          <Link href="/app/virtual-lab" className={styles.loginButton}>
            Virtual Labs
          </Link>
        </div>
        <div className={styles.hamburger}>
          <button type="button" onClick={() => setShowMenu(!showMenu)} aria-label="Popup menu">
            <IconMenu />
          </button>
        </div>
      </div>
      <PopupMenu visible={showMenu} onChange={setShowMenu} />
    </>
  );
}
